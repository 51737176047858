footer {
	background: @footer_bg;

	* {
		color: @footer_color;
	}

	a {
		text-decoration: none;
	}
	
}

.footer-contacts {
	padding-right: 15px;
}

ul#footer-menu a {
	font-size: 14px;
	line-height: 16px;
	margin: 0 15.7px;
}

footer#colophon {
	padding-top: 30px;
	padding-bottom: 58px;
}

ul#footer-menu {
	padding: 0;
}

.company-address,
.copyr {
	font-size: 14px;
	line-height: 16px;
}

.row.line-1 {
	margin-bottom: 23px;
}

.footer-contacts a {
	font-weight: 700;
	font-size: 18px;
	line-height: 23px;
	position: relative;
	padding-left: 39px;
	white-space: normal;
}

.footer-nav {
	flex-grow: 1;
}

a.footer-phone {
	margin-right: 29px;
}

a.footer-phone:before {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	background: url(../images/phone.svg);
	background-repeat: no-repeat;
	left: 0;
}

a.footer-email:before {
	content: '';
	position: absolute;
	width: 30px;
	height: 30px;
	background: url(../images/email.svg);
	background-repeat: no-repeat;
	left: 0;
}