// body, html
html {
	background: @html_color;
}

body {
	min-width: 320px;
	margin: 0 auto;
	font-family: @font_family;
	font-size: @font_size;
	font-weight: @font_weight;
	color: @font-color;
	overflow-x: hidden;
	max-width: @body_max_width;
	margin: 0 auto;
	background: @body_color;
	line-height: 30px;
}

* {
	outline: none !important;
}

a {
	color: @accent_color;
}

.hidden {
	display: none;
}

.post-707 h1.entry-title {
	font-weight: 900;
	font-size: 64px;
	line-height: 130%;
	letter-spacing: .03em;
	margin-bottom: 50px;
}

.bcapt {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 20px;
	margin-top: 30px;
}

.brief select {
	height: 48px;
	border-radius: 0 30px 30px 0!important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url(../images/arrow.svg);
	background-size: 15px;
	background-repeat: no-repeat;
	background-position: calc(100% - 20px);
}

span.wpcf7-form-control-wrap.dop-func,
span.wpcf7-form-control-wrap.wants {
	margin-top: 25px!important;
	display: block;
}

.sticky-btn {
	margin-left: 20px;
	margin-top: 15px;
}

//section 
.section {
	position: relative;
	padding: @section_padding 0;
}

.section.colored {
	background :@color_1;
}

.section.accent {
	background :@accent_color;
}



body.compensate-for-scrollbar {
	overflow: auto !important;
	margin: 0 auto!important;
}

//spacing
.mb-6	{
	margin-bottom: 4rem !important;
}

//colors
.light * {
	color: #ffffff !important;
}

.dark * {
	color: @font-color;
}

//masking
.mask {
	position: relative;
}

.mask:before {
	content: '';
	background: linear-gradient(to bottom,rgba(27,9,1,.69),transparent);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
}

.title,
h1,h2,h3,h4,h5,h6 {
	font-family: @title_font;
}

.title-wrapper {

}

.title {
	font-weight: bold;
	font-size: 36px;
	line-height: 42px;	
	color: #000000;
}

.pl-60 {
  padding-left: 50px !important;
}

.p-14 {
font-weight: 500;
font-size: 14px;
line-height: 20px;
}

.mb-34 {
	margin-bottom: 34px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-90 {
	margin-bottom: 90px;
}

.w-100 {
	width: 100%;
}

.white-bg {
	background: #fff;
}

#banner.p-44.__v2 {
	padding: 44px 0 88px;
}

div.wpcf7 .ajax-loader,
div.wpcf7-validation-errors, div.wpcf7-acceptance-missing,
span.wpcf7-not-valid-tip {
	display: none !important;
}

.section.__v2{
	padding: 58px 0;
}

.circle {
	border-radius: 50px !important;
}

.mb-35 {
	margin-bottom: 35px;
}

.mp-0 {
	margin: 0 !important;
	padding: 0 !important;
}

.box-circle {
	background: #fff;
	border-radius: 50px !important;
	box-shadow: 0 4px 10px rgba(0,0,0,.1);
}

.accent-bg {
	background: @accent_color;
}


.contact-box a,
ul#footer-menu a,
header.site-header a,
a.company-phone:hover,
button.callback:hover,
a.footer-phone:hover,
a.footer-email:hover {
	transition: .3s ease;
}

.contact-box a:hover,
a.company-phone:hover,
button.callback:hover,
a.footer-phone:hover,
a.footer-email:hover {
	color: @accent_color!important;
}

a.company-phone:hover {
	border-bottom: 2px solid #000000;
}

.btn.grey:hover {
	background: #000;
}
