@media(min-width: 991px) {
	.just-mobile {
		display: none !important;
	}
}




@media (max-width: 1199px) {

	.yellow-box a, .yellow-box-item,
	.yelow-box-title {
		font-size: 16px;
	}

	#tab-slider .tab-nav {
		min-height: 550px;
	}

	#tab-slider .tab-nav.active img {
    transform: translate(105px, 119px);
	}

	#tab-slider .tab-nav img {
		transform: translate(105px,-50px);
		max-width: 180px;
	}

	#steps-v2 .tab-nav-inner.active+.tab-nav-inner .tab-nav:before {
    content: '';
    left: -105px;
	}

	#steps-v2 .tab-nav {
		font-size: 120px;
	}

	.about-digit .value {
    font-weight: 900;
		font-size: 100px;
	}

	.digit-circle {
    width: 200px;
    height: 200px;
	}

	.col-md-8.__digitmod {
    padding-left: 5%;
	}

	.vertical-text {
		.s-141 {font-size: 90px;}
		.s-36 {font-size: 28px;}
		.s-40 {font-size: 30px;}
		.s-48 {font-size: 38px;}
		.s-64 {font-size:44px;}

		.line.l-1 { left: 44px; top: 88%;} 
		.line.l-2 { left: 106px; top: 91%;}
		.line.l-3 { left: 150px; top: 96%;}
		.line.l-4 { left: 194px; top: 95%;}
	}

	.vertical-text.__design .line.l-2 {	top: 89%;	}	
	.vertical-text.__design .line.l-3 {	top: 94%;}	
	.vertical-text.__design .line.l-4 {	top: 89%;	}

	.banner-title {
		font-size: 50px;
	}

	.site-branding img {
    width: 100%;
    height: auto;
	}
	.col.phone {
    flex: 0 0 175px;
    max-width: 175px;
	}
	.col.logo {
    flex: 0 0 100px;
    max-width: 100px;
	}
	ul.menu a {
		font-size: 14px;
	}
	a.company-phone {
		font-size: 20px;
	}
	.footer-contacts a {
    font-weight: 700;
    font-size: 16px;
    padding-left: 30px;
	}
	ul#footer-menu a {
		margin: 0 13.7px 0 0;
	}
	a.footer-phone:before {
    width: 20px;
    height: 20px;
    background-size: cover;
	}
	a.footer-email:before {
    width: 24px;
		height: 24px;
		background-size: cover;
	}

	#steps .tab-nav-inner:last-child:after {
    left: -17px;
	}

	.inner-text p {
    margin-top: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 26px;
	}

	#reviews {
		padding: 0px 115px;
	}

	.section {
    padding: 60px 0;
	}

	.title {
    font-size: 32px;
		line-height: 38px;
	}

	body {
    font-size: 16px;
    line-height: 28px;
	}

	.box .box-title {
    font-size: 20px;
		line-height: 24px;
	}

	.box .box-image img {
    max-width: 75px;
	}

	.box br {
    display: none;
	}

	.box .box-image {
			width: 100%;
			max-width: 100px;
	}

	.mb-60,.mb-90 {
    margin-bottom: 50px;
	}

	.creature-wrapper .inner-bottom {
    font-size: 16px;
		line-height: 24px;
	}

	.footer-nav {
    padding-left: 15px;
	}

}

@media (max-width: 991px) {
	#creature {
		overflow: hidden;
	}
	.creature-wrapper .nums .num {
		font-size: 60px;
	}
	.contact-box {
		max-width: 100%;
	}
	#yamap {
    width: 100%;
    height: 300px;
    position: relative;
    left: 0;
    top: 0;
    margin-top: 40px;
	}

	#tab-slider .tab-title {
    font-size: 32px;
    line-height: 38px;
	}

	#tab-slider .tab-nav.active img {
    transform: translate(81px, 119px);
	}

	#tab-slider .tab-nav img {
		transform: translate(81px,0px);
		max-width: 112px;
	}

	#tab-slider .tab-nav {
    min-height: 411px;
	}

	#tab-slider .tab-nav span {
		font-size: 24px;
		line-height: 28px;
		left: 38px;
	}

	#callback .title-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
	}

	._v4 .vantage-title {
    font-size: 18px;
    line-height: 20px;
	}

	ul.accordion .item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
	}

	ul.accordion .toggle span.menu-icon {
    width: 30px;
    height: 20px;
		left: 12px;
	}

	ul.accordion .toggle span.menu-icon span.line:before {
    transform: translate(0,-8px);
	}

	ul.accordion .toggle span.menu-icon span.line:after {
    transform: translate(0,8px);
	}

	ul.accordion .toggle {
    padding: 16px 20px 20px 55px;
		padding-left: 55px;
	}

	ul.accordion .toggle span.menu-icon span.line, ul.accordion .toggle span.menu-icon span.line:after, ul.accordion .toggle span.menu-icon span.line:before {
		height: 3px;
	}

	.capability.mb-35 .d-flex{
		flex-direction: column;
	}

	.capability .description {
    width: 100%;
    padding: 0;
		text-align: center;
		margin-top: 10px;
	}

	span.number-icon {
    display: none;
	}

	#steps-v2 .tab-nav-inner.active+.tab-nav-inner .tab-nav:before {
		left: 0px;
		width: 88px;
	}

	footer#colophon {
    padding-bottom: 100px;
	}
	.pl-60 {
    padding-left: 15px!important;
	}

	.just-mobile .title {
    padding: 25px 18px 16px;
    border-bottom: 3px solid @accent_color;
    margin-bottom: 21px;
	}

	#steps .tab-nav-inner:last-child:after {
		left: -31.444444px;
	}

	ul#footer-menu {
		padding: 0;
		justify-content: center;
		margin-bottom: 15px;
	}

	a.company-phone {
		margin-top: 0;
	}

	.col.nav.p-0 {
		opacity: 0;
		visibility: hidden;
    position: fixed;
    left: -200%;
    top: 0;
    background: #ffffff;
    height: 100vh;
    z-index: -1;
		width: 100%;
		transition: left .4s ease;
	}

	.menu-open .col.nav.p-0 {
		opacity: 1;
		visibility: visible;
		display: block;
		left: 0;		
		z-index: 9999;
	}

	.menu-main-container ul#primary-menu {
    display: flex;
		flex-direction: column;
		padding: 0;
		
		a {
			font-size: 16px;
			margin-bottom: 10px!important;
			display: inline-block;
		}
	}

	.col.phone {
		margin-left: auto;
	}

	.footer-contacts {
		width: 100%;
	}

	.footer-contacts .d-flex {
		justify-content: center;
	}

	.row.line-2 {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.row.line-2 .text-right {
		text-align: center !important;
		margin-bottom: 20px;
	}

	ul#footer-menu {
		flex-wrap: wrap;
	}

	ul#footer-menu a {
		white-space: nowrap;
	}

	.header-row {
		margin-right: 0;
	}

	.single-review.d-flex {
		flex-direction: column;
		justify-content: center;
	}

	.review-img img {
    max-width: 280px;
    border: 5px solid #fce300;
    margin: 0 auto;
    margin-bottom: 35px;
	}

	.box .box-image img {
		margin-right: 20px;
	}

	.box .box-title {
    font-size: 18px;
	}

	.creature-wrapper .right-col:after {
    width: 290px;
    height: 409px;    
    background-size: cover;  
    right: -90px;
	}

	.white-side:before {
    background: transparent;
	}

	.form-inputs-right .d-flex {
    flex-wrap: wrap;
	}

	.col.form-text-col {
    padding-left: 0;
    margin-top: 15px;
	}

}


// Mobile 
@media (max-width: 767px) {

	.post-707 h1.entry-title {
    font-size: 34px;
    margin-bottom: 0px;
	}

	.creature-wrapper .btn {
    padding: 13px 35px;
    width: 100%;
	}

	.creature-wrapper .small-title {
    font-size: 22px;
    line-height: 26px;
		margin-bottom: 24px;
		text-align: center;
	}

	.creature-wrapper .nums .num {
    font-size: 80px;
	}

	.creature-wrapper .nums {
    flex-direction: column;
    text-align: center;
	}

	.creature-wrapper .nums .description {
    font-size: 20px;
    line-height: 1.3;
    margin-left: 10px;
    max-width: 80%;
    margin-top: 0;
	}

	.creature-wrapper .right-col .inner {
    padding-top: 0;
    padding-left: 0;
	}

	.socials-links.__portfolio {
		width: 100%;
		max-width: 100%;
	}

	.socials-links.__portfolio a {
		width: 25%;
	}

	div#callback .title {
    text-align: center;
	}

	#banner.p-44.__v2 {
    padding: 44px 0 20px;
	}

	.page-template-promotion #banner {
    padding: 44px 0 45px;
	}

	.col.button-send-col {
    flex-basis: 100%;
	}

	body .modal input.btn {
		width: 100%;
	}

	.wpcf7-submit.btn {
		width: 100%;
		padding: 15px 0;   
	}

	.vantages-vertical {
    margin-top: 65px;
}

	.sticky-nav {
    z-index: 9999;
	}

	ul.cityes-list {
    column-count: 2;
	}

	#tab-slider .tab-nav img {
    transform: unset;
		max-width: 85px;
		right: 0;
		transform: translate(-16px,-35px);
	}

	#tab-slider .tab-nav.active img {
    transform: translate(-16px,-55px);
	}

	#tab-slider .tab-nav-inner.col {
    min-width: 100%;
	}

	#tab-slider .tab-nav-wrapper {
   flex-wrap: wrap;
	}

	#tab-slider .tab-nav {
    min-height: auto;
    padding: 30px 0;
	}

	#tab-slider .tab-nav span {
    font-size: 24px;
    line-height: 28px;
		left: 15px;
    transform: rotate(0);
    position: relative;
	}

	#includes .nth-1 {
    opacity: .2!important;
	}

	.slide-text-title {
		top: -5px;
	}

	#includes {
    padding-left: 0;
    padding-top: 55px;
    position: relative;
	}

	#includes .owl-nav {
    top: 50px;
    transform: translate(0,0);
	}	

	#tab-slider {
    margin-top: 0;
    padding-top: 25px;
	}

	div#tab-slider .tab-content-wrapper {
    padding-top: 25px;
	}

	.breadcrumbs-wrapper {
    display: none;
	}

	.__creating .banner-subtitle {
    padding-bottom: 25px;
	}

	#car {
    left: 0;
		bottom: -11px;
		width: 63px;
		height: 63px;
	}

	#steps-v2 .tab-nav {
    font-size: 70px;
	}

	#steps-v2 .tab-nav-inner.active+.tab-nav-inner .tab-nav:before {
		display: none;
	}

	.contact-box .box-item {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 28px;
	}

	.contact-box {
    max-width: 488px;
    padding: 15px 15px 20px 15px;
	}

	.col-md-4.__digitmod {
		display: none;
	}

	.about-digit .value {
    font-weight: 900;
    font-size: 60px;
	}

	.col.digit-item {
    margin-bottom: 25px;
	}

	.about-digit .digit-item {
    min-width: 50%;
    flex-grow: 0;
    width: 100%;
    text-align: center;
	}

	.about-digit .description {
		max-width: 100%;
	}

	.about-digit .row {
    justify-content: center;
	}

	body, html {
		overflow-x: hidden;
	}

	body .modal {
    min-width: 280px!important;
    padding: 35px 15px!important;
	}

	body .hamburger {
    margin-left: 15px;
	}

	.footer-contacts .d-flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
	}

	.col.logo {
    flex: 0 0 90px;
    max-width: 90px;
	}

	a.footer-phone {
    margin-right: 0;
    margin-bottom: 15px;
	}

	.company-address {
    padding: 0 15px;
	}


	.just-desctop {
		display: none;
	}

	.menu.just-desctop {
		display: block;
		position: absolute;
		left: -1000px;
		visibility: hidden;
		opacity: 0;
	}

	.just-mobile {
		display: block;
	}

	#reviews {
    padding: 0 0 40px;
	}

	#blog-carousel button.owl-next,
	#blog-carousel button.owl-prev,
	#reviews button.owl-next,
	#reviews button.owl-prev {    
    bottom: 0;
	}

	.portfolio-grid:after {
    width: 97px;
    height: 56px;
    background-size: cover;
	}

	.vantage-title {
    font-size: 22px;
    line-height: 26px;
    padding-top: 10px;
	}

	.single-vantage {
    margin-bottom: 15px;
	}

	.banner-vantages {
    margin-right: 0;
    padding-top: 50px;
	}

	#our-clients {
    padding: 0;   
	}

	.banner-title {
    font-size: 7vw;
	}
	.btn {
		font-size: 15px;
	}
	.sticky-nav>button {
		width: 33%;
		box-shadow: none;
	}
	.sticky-nav {			
			top: auto;
			bottom: 0;		
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			background: #dedede;
	}
	#scroll-top {
		bottom: 70px;
	}
	.title {
    font-size: 25px;
    line-height: 1.2;
	}
	.section {
    padding: 40px 0;
	}
	.mb-60, .mb-90 {
    margin-bottom: 30px !important;
	}
	.single-vantage {
		padding-left: 57px!important;
	}
	.inner-text p {
    font-size: 14px;
		line-height: 22px;
	}
	.inner-title {
    font-size: 20px;
    line-height: 1.2;
	}

	// .creature-wrapper .right-col:after {
	// 	display: none;
	// }

	.creature-wrapper .left-col, .creature-wrapper .right-col {
		padding-top: 40px;
		padding-right: 0;
	}

	.creature-wrapper .right-col {
		background: transparent !important;
    padding-bottom: 0;
	}

	#callback .title-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
	}

	#creature:before {
		display: none;
	}

	// .creature-wrapper>div {
	// 	width: 100% !important;
	// 	flex: 0 0 100% !important;
	// 	max-width: 100% !important;
	// }

	.creature-wrapper,
	.form-inputs-wrapper {
    flex-wrap: wrap;
		flex-direction: column;
		&>div {
			width: 100% !important;
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}

	.form-inputs-left .form-text {
    display: none;
	}

	.form-inputs-right {
    margin-top: 30px;
	}

	div#our-clients {
    padding-bottom: 70px;
	}

	button.owl-prev,
	button.owl-next {
    top: auto!important;
    transform: none;
    bottom: 0;
	}

	#steps .tab-nav-inner {
    width: 25%;
    height: auto;
    margin-bottom: 28px;
	}

	#steps .tab-nav-inner:nth-child(odd) {
    justify-content: unset;
	}

	.tab-nav-wrapper.d-flex {
    flex-wrap: wrap;
	}
	#steps .tab-nav-inner:nth-child(even):after,
	#steps .tab-nav-inner:nth-child(even):before {
		display: none;
	}
	#steps .tab-nav-inner:last-child:after {
   	display: block;
    width: 100px;
    height: 90px;
    background-size: cover;
    top: -17px;
    left: 14px!important;
	}

	#steps .tab-nav:before {
		display: none;
	}

	#steps .tab-nav-wrapper {
    margin-bottom: 10px;
	}

	.mb-80 {
    margin-bottom: 40px;
	}

	#steps .tab-nav {
    font-size: 25px;
    line-height: 27px;
    width: 50px;
		height: 50px;
	}

	#steps .tab-nav.active span {
		transform: translate(-50%,-50%);
	}

	.banner-vantages .single-vantage._v4 {
		margin-bottom: 40px;
	}

	div#modal-tnx .title {
    font-size: 21px;
    margin-top: 30px;
	}

	body .modal button.fancybox-close-small {
    right: 0px;
    top: 0px;
	}

	
	.review-img img {
		max-width: 175px;
		min-width: unset;
	}

	.review-img {
    margin-right: 0;
	}

	.client img {
    padding: 0 20px;
	}	

	.creature-wrapper .right-col:after,
	.right-col .inner-bottom.text-right {
    display: none;
	}

	body .modal input.btn,
	.form-inputs-right .btn {
    font-size: 16px;
	}

	#banner.p-44 {
    padding: 20px 0;
	}

	.banner-subtitle {
		font-size: 17px;
	}

}

// Landscape
@media (max-width: 667px) and (max-height: 480px) {


}

@media(max-width: 500px) {

	#steps .tab-nav-inner:last-child:after {
	 left: -1px!important;
 }

}

@media(max-width: 425px) {


}

@media(max-width: 400px) {
	#tab-slider .tab-nav span {
		font-size: 18px;
	}

	#tab-slider .tab-nav span {
		left: 10px;
	}

	body .modal {
    min-width: 100%!important;
    padding: 35px 8px!important;
	}
}


@media (max-width: 370px) {

	#steps .tab-nav-inner:last-child:after {
    left: -13px!important;
	}

	.btn {
    font-size: 13px;
	}

	.col.phone {
    flex: 0 0 145px;
    max-width: 145px;
	}

	.col.logo {
    flex: 0 0 70px;
    max-width: 70px;
	}

	a.company-phone {
    font-size: 15px;
	}
}
