header.site-header {
	padding: 18px 0 14px;

	a {
		color: #000;
		text-decoration: none;
		white-space: nowrap;
	}
}

.col.logo {
	-webkit-box-flex: 0;
	flex: 0 0 155px;
	max-width: 155px;
	padding-right: 0;
}

.col.phone {
	-webkit-box-flex: 0;
	flex: 0 0 215px;
	max-width: 215px;
}

.col.nav {
	display: flex;
	align-items: center;
}

a.company-phone {
	font-weight: 900;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	white-space: nowrap;
	border-bottom: 2px solid #FCE300;
	padding-bottom: 3px;
	margin-top: 22px;
	margin-bottom: 6px;
}

.callback {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-transform: none;
	background: transparent;
	border: none;
	outline: none!important;
	cursor: pointer;
}

.header-row {
	// margin-right: -15px;
	padding-bottom: 8px;
	border-bottom: 2px solid #000;
}

body .wp-easy-query-paging {
	margin: 50px 0 0;
}
