/*
* Banner
*/
.banner-title {
	font-weight: 900;
	font-size: 64px;
	line-height: 130%;
	letter-spacing: 0.03em;
}

.banner-subtitle {
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	padding-top: 8px;
	padding-bottom: 20px;
}

.section.banner {
	padding-top: 65px;
	padding-bottom: 90px;
}

.banner-buttons {
	padding-top: 28px;
}

.banner-buttons .btn:not(:last-child) {
	margin-right: 26px;
}

.banner-buttons .btn {
	width: 100%;
	max-width: 262px;
	padding: 11px;
}

.banner-vantages {

	margin-right: -44px;
	padding-top: 85px;

}

.single-vantage {
	padding-left: 69px !important;
	position: relative;
}

.vantage-num {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.vantage-num:before {
	content: '';
	position: absolute;
	width: 35px;
	height: 35px;
	background: #FCE300;
	border-radius: 50px;
	bottom: -10px;
	right: -4px;
	z-index: -1;
}

.vantage-num {
	font-weight: 900;
	font-size: 36px;
	line-height: 42px;
	text-align: center;
	color: #000000;
}

.vantage-title {
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: #000000;
	padding-top: 14px;
}

.vantage-description {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #7d7d7d;
	padding-top: 12px;
	max-width: 250px;
}

/*
* End Banner
*/


/*
* Box
*/

.two-column-box.row>div {
	margin-bottom: 30px;
}

.box {
	background: #FFFFFF;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 17px;
	height: 100%;

	.box-title {
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		background: #FCE300;
		padding: 11px 20px;
		border-radius: 0 50px 50px 0;
	}

	.box-description {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #7D7D7D;
	}

	.box-image {
		width: 100%;
		max-width: 120px;
	}

	.two-column-bottom {
		padding-top: 13px;
	}

}

/*
* End Box
*/

/*
* Text inner
*/
.inner-text p {
	margin-top: 0;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	margin-bottom: 26px;
}

.inner-title {
	font-weight: 600;
	font-size: 24px;
}

/*
* End Text inner
*/

/*
* Portfolio
*/

.portfolio-single {
	height: 100%;
}

a.prev-post {
	position: relative;
	display: inline-block;
	background: url(../images/arrow-left.svg)!important;
	background-repeat: no-repeat!important;
	background-size: 25px!important;
	background-position: top left!important;
	width: 100px;
	height: 15px;
	&:before {
		content: '';
    position: absolute;
    width: calc(100% - 23px);
    left: 23px;
    bottom: 0;
    height: 2px;
    background: #000;
	}
}

a.next-post {
	position: relative;
	display: inline-block;
	background: url(../images/arrow-right.svg)!important;
	background-repeat: no-repeat!important;
	background-size: 25px!important;
	background-position: top right!important;
	width: 100px;
	height: 15px;
	&:before {
		content: '';
    position: absolute;
    width: calc(100% - 23px);
    right: 23px;
    bottom: 0;
    height: 2px;
    background: #000;
	}
}

.attachment-rocket-portf {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.portfolio-grid {
	position: relative;
	padding-bottom: 35px;
	padding-left: 15px;
	padding-right: 15px;

	.portfolio-single {
		position: relative;

		img {
			transition: .5s ease-in-out;
		}

		a {
			overflow: hidden;
		}
	}

	img.hidden {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
	}

	.portfolio-single:hover img.hidden {
		visibility: visible !important;
		opacity: 1 !important;
	}

}

.portfolio-grid.no-deco:after {
	display: none;
}

a.more-portfolio{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 125px;
	height: 75px;
	background: url(../images/saturn.svg);
	background-repeat: no-repeat;
}

/*
* End Portfolio
*/

/*
* Our Clients
*/

#reviews {
	padding: 0 156px;
	position: relative;
}

#includes .owl-item {
	transition: .3s ease;
}

#our-clients {
	padding: 0 123px;
	position: relative;
	.client img {
		max-width: 150px;
		margin: 0 auto;
	}
}

.owl-carousel {

	.owl-nav button {
		top: 50%;
		transform: translate(0, -100%);
		position: absolute;
		padding: 0;
		height: 15px;
		width: 100px;
		display: block;
		padding: 0;

		span {
			font-size: 0;
		}
	}

	.owl-nav {
		position: static;
		width: 100%;
	}

	button.owl-prev {
		left: 0;
		background: url(../images/arrow-left.svg) !important;
		background-repeat: no-repeat !important;
		background-size: 25px !important;
		background-position: top left !important;
		

		&:before {
			content: '';
			position: absolute;
			width: calc(100% - 23px);
			left: 23px;
			bottom: 0;
			height: 2px;
			background: #000;
		}
	}

	button.owl-next {
		right: 0;
		background: url(../images/arrow-right.svg) !important;
		background-size: 25px !important;
		background-position: top right !important;
		background-repeat: no-repeat !important;

		&:before {
			content: '';
			position: absolute;
			width: calc(100% - 23px);
			right: 23px;
			bottom: 0;
			height: 2px;
			background: #000;
		}
	}
}

#blog-carousel button.owl-prev,
#blog-carousel button.owl-next,
#reviews button.owl-prev,
#reviews button.owl-next {
	top: auto;
}

#blog-carousel button.owl-prev,
#blog-carousel button.owl-next {
	bottom: 0;
}

#blog-carousel button.owl-prev {
	left: 10px;
}

#blog-carousel button.owl-next {
	right: 10px;
}

#blog-carousel {
	padding-bottom: 47px;
}

/*
* End Our Clients
*/


/*
* Rocket
*/

#steps {
	overflow: hidden;

	.tab-nav {
		position: relative;
	}

	.tab-nav:before {
		content: "";
		position: absolute;
		width: 64px;
		height: 110px;
		background: url(../images/rocket.svg);
		opacity: 0;
		transform: translate(0, -160px);
		background-repeat: no-repeat;
		transition: .4s ease-in-out;
	}

	.tab-nav.active:before {
		opacity: 1;
		transform: translate(0, -67px);
		background-repeat: no-repeat;
		transition: .6s cubic-bezier(0, 0.55, 0.45, 1);
		transition-delay: 0.4s;
	}

	.tab-inner-title {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #000000;
		margin-bottom: 20px;
	}

	.tab-nav-wrapper {
		margin-bottom: 84px;
	}

	.tab-nav {
		font-weight: 900;
		font-size: 36px;
		line-height: 42px;
		color: #DEDEDE;
		width: 80px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 50px;
		user-select: none;
		outline: 0;
		z-index: 1;

		&:hover {
			cursor: pointer;
		}

		&.active {
			color: #000;
		}
	}

	.tab-nav.active span {
		transform: translate(17%, 12%);
		transition: .4s ease;
		transition-delay: 0.2s;
	}

	.tab-nav span {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: relative;
		display: inline-block;
		position: relative;
		line-height: 100%;
		transition: .4s ease;
	}

	.tab-nav-inner {
		height: 264px;
		width: 12.5%;
		display: flex;
		justify-content: center;
		position: relative;
	}

	.tab-nav-inner:nth-child(odd) {
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	.tab-nav-inner:nth-child(even):before {
		content: '';
		position: absolute;
		width: 60px;
		height: 181px;
		background: url(../images/dashed-line.svg);
		background-repeat: no-repeat;
		left: -30px;
		top: 42px;
	}

	.tab-nav-inner:nth-child(even):after {
		content: '';
		position: absolute;
		width: 60px;
		height: 181px;
		background: url(../images/dashed-line.svg);
		transform: scale(-1, 1);
		background-repeat: no-repeat;
		right: -30px;
		top: 42px;
	}


	.tab-nav-inner:last-child:after {
		content: '';
		position: absolute;
		width: 153px;
		height: 135px;
		background: url(../images/steps-star.svg);
		background-repeat: no-repeat;
		top: -25px;
		left: -4px;
	}

	.tab-nav-inner:last-child .tab-nav {
		box-shadow: none;
	}

	.tab-nav-inner:last-child:before {
		content: '';
		position: absolute;
		width: 58px;
	}

}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}

.review-text p {
	margin: 0;
	padding-right: 10px;
}

p.review-author {
	margin-top: 25px;
}

.review-img {
	margin-right: 27px;
	img {
		min-width: 295px;	
		border: 5px solid @accent_color;
	}
}


#creature:before {
	content: '';
	position: absolute;
	width: 40%;
	height: 100%;
	background: #fff;
	right: 0;
	top: 0;
}

.creature-wrapper {
	position: relative;

	.right-col {
		background: #fff;
		flex: 0 0 24.7%;
		max-width: 24.7%;
		z-index: 1;
		padding-bottom: 508px;

		&:after {
			content: '';
			position: absolute;
			width: 360px;
			height: 509px;
			background: url(/wp-content/uploads/2020/04/bill.png);
			background-repeat: no-repeat;
			bottom: 0;
			right: -10px;
		}
	}

	.right-col .inner {
		padding-top: 9px;
		padding-left: 27px;
	}

	.left-col {
		padding-right: 70px;
		flex: 0 0 75.3%;
		max-width: 75.3%;
		background: #f5f5f5;
		z-index: 1;
	}

	.left-col,
	.right-col {
		padding-top: 94px;
	}

	.small-title {
		font-weight: bold;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 60px;
	}

	.nums {
		align-items: center;
		margin-bottom: 60px;

		.num {
			font-weight: 900;
			font-size: 96px;
			line-height: 99%;
			letter-spacing: 0.03em;
			color: @accent_color;
		}

		.description {
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			margin-left: 9px;
			max-width: 139px;
			margin-top: 14px;

			&.full {
				max-width: 100%;
				margin: 0;
			}
		}

	}

	.inner-bottom {
		font-weight: bold;
		font-size: 17px;
		line-height: 30px;
		padding-top: 54px;

		.author {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			margin-top: 10px;
		}
	}
}


#callback {

	.title-wrapper {
		padding-top: 60px;
		padding-bottom: 60px;
	}

}


.form-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #7D7D7D;
}

.home .form-inputs-right {
	flex: 0 0 51%;
	max-width: 51%;
}

.form-inputs-right {
	flex: inherit;
	max-width: 100%;
	flex-grow: 1;
}

.form-inputs-left {
	flex: 0 0 41%;
	max-width: 41%;
	margin-right: 3%;
}

.form-inputs-wrapper {
	padding: 28px 0;
	z-index: 1;
	position: relative;
	background: @color_1;
}

.form-wrapper {
	position: relative;
}

.white-side:before {
	content: '';
	position: absolute;
	width: 40%;
	height: 100%;
	top: 0;
	right: 0;
	background: #fff;
}

.col.button-send-col {
	padding: 0;
}

.form-wrapper p {
	margin: 0 !important;
}

.form-inputs-left .form-text {
	margin-top: 33px;
	max-width: 324px;
}

.form-inputs-right .d-flex {
	margin-top: 22px;
}

.form-inputs-right .btn {
	text-transform: none;
}

.single-blog-title a {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	color: #000;
	text-decoration: none;
}

.single-blog p {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

p.entry-meta {
	margin: 0;
	margin-top: 5px;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
}

.thumbnail-placeholder {
	height: 200px;
	width: 100%;
	background: #DEDEDE;
}

.single-blog-info {
	padding: 17px 20px;
	box-sizing: border-box;
}

.single-blog-title {
	line-height: 23px;
}

.single-blog {
	background: #FFFFFF;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	margin: 3px 10px 10px 10px;
}

#our-blog {
	padding-top: 62px;
	padding-bottom: 55px;
}

.container.__owlfix {
	padding: 0 9px;
}

.sticky-nav {
	width: 55px;
	position: fixed;
	right: 0;
	top: 28%;
	z-index: 99999;

	button {
		width: 55px;
		height: 70px;
		box-shadow: none;
		border: none;
		padding: 0;
		margin: 0;
		display: block;
		cursor: pointer;
		background: #dedede;
		outline: none !important;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		transition: .3s ease;

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
		&:hover,
		&.marked {
			background: #FCE300;
		}
	}

}

.sticky-nav #location-modal:after {
	background: url(../images/location-modal.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.sticky-nav #question-modal:after {
	background: url(../images/question-modal.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.sticky-nav #callback-modal:after {
	background: url(../images/callback-modal.svg);
	background-position: center;
	background-repeat: no-repeat;
}


label.required:after {
	content: ' *';
	font-size: 17px;
	color: #C60024;
	font-weight: bold;
}

ul.cityes-list {
	list-style: none;
	margin: 0;
	padding: 0;
	column-count: 3;

	a {
		text-decoration: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #7D7D7D;
	}
}

li.active.city-link a {
	position: relative;
}

li.active.city-link a:before {
	content: '';
	width: 100%;
	height: 2px;
	position: absolute;
	bottom: -1px;
	background-image: linear-gradient(90deg, @accent_color, @accent_color 75%, transparent 75%, transparent 100%);
	background-size: 11px 1px;
}

/*
* Breadcrumbs
*/

.breadcrumbs-wrapper {
	position: relative;
	z-index: 2;
}

.breadcrumbs span {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

i.home-icon {
	position: relative;
	top: -6px;
	display: block;
	width: 30px;
	height: 27px;
	background: url(../images/home.svg);
	margin-right: 6px;
}

.breadcrumbs {
	display: flex;
	align-items: flex-end;
}

.breadcrumbs>span:first-child a {
	padding: 0;
}

.breadcrumbs a {
	text-decoration: none;
	color: #000;
	padding-right: 5px;
}

span.breadcrumbs__separator {
	padding-right: 4px;
}

/*
* End Breadcrumbs
*/


#banner.p-44 {
	padding: 44px 0;
}

.banner-vantages .single-vantage._v4 {
	padding-left: 0 !important;
	display: flex;
	flex-direction: column;
	
	a {
		margin-top: auto;
	}

	.vantage-inner {
		padding-left: 56px;
		margin-bottom: 20px;
	}
}

.creature-wrapper .right-col.steve:after {
	background: url(/wp-content/uploads/2020/04/steve.png);
}


.__creating {
	padding-top: 49px;
	padding-bottom: 27px;

	.banner-subtitle {
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 42px;
		padding-top: 0;
		padding-bottom: 59px;
	}
}


.capability {

	.box-circle {
		width: 98px !important;
		height: 98px !important;

		img {
			padding: 10px;
			text-align: center;
			max-width: 76px;
			margin: 0 auto;
			display: block;
			position: relative;
			top: 50%;
			transform: translate(0, -50%);
		}

	}

	.description {
		padding-left: 35px;
		width: calc(100% - 132px);
		font-weight: 500;
		font-size: 18px;
		line-height: 30px;
	}

}

.portfolio-grid.__v2 a.more-portfolio {
	content: '';
	width: 75px;
	height: 86px;
	background: url(../images/ufo-portfolio.svg);
	background-repeat: no-repeat;
}

ul.accordion {
	list-style: none;
	padding: 0;
	margin: 0;

	.toggle {
		width: 100%;
		border: none;
		background: #FFFFFF;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		padding: 16px 20px 20px 96px;
		padding-left: 96px;
		margin-bottom: 10px;
		text-align: left;
		cursor: pointer;
		position: relative;

		span.menu-icon {
			width: 50px;
			height: 36px;
			display: inline-block;
			position: absolute;
			left: 18px;
			top: 50%;
			transform: translate(0, -50%);

			span.line:after,
			span.line:before,
			span.line {
				transition: .3s ease;
				position: absolute;
				width: 100%;
				height: 5px;
				background: #DEDEDE;
			}

			span.line {
				top: 50%;
				transform: translate(0, -50%);

				&:before {
					content: "";
					transform: translate(0, -14px);
				}

				&:after {
					content: "";
					transform: translate(0, 14px);
				}
			}

		}

		&.active {

			span.line:after,
			span.line:before,
			span.line {
				background: #000000 !important;
			}
		}

	}

	.item-title {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-transform: none;
	}

	.inner {
		overflow: hidden;
		display: none;

		&.show {
			padding: 30px 0 40px;
		}
	}
}


.number-icon {
	font-weight: 900;
	font-size: 36px;
	line-height: 42px;
	position: relative;
	z-index: 1;
	margin-right: 6px;

	&:before {
		content: '';
		position: absolute;
		width: 35px;
		height: 35px;
		background: #fce300;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
		bottom: -10px;
		right: 2px;
		z-index: -1;
	}
}

.box.__v2 {
	padding: 18px 47px 23px;

	.box-title {
		background: transparent;
		padding: 0;
		padding-top: 5px;
		padding-bottom: 30px;
		border-radius: 0;
	}

	.box-description {
		min-height: 100px;
		padding-bottom: 55px;
	}

}

.inner p {
	margin-top: 0;
}

blockquote p {
	margin: 0;
}

blockquote:before {
	content: '';
	width: 4px;
	height: calc(100% - 13px);
	background: #000;
	left: 2px;
	top: 5px;
	position: absolute;
}

blockquote {
	margin: 0;
	padding: 0;
	margin-bottom: 43px;
	padding-left: 44px;
	position: relative;
}

.creature-wrapper .btn {
	padding: 10px 35px;
	min-width: 260px;
	background: #fff;
	&:hover {
		background: #000;
	}
}

#creature .vantages-vertical {
	padding-left: 50px;
}

.vantages-vertical .single-vantage {
	padding-left: 69px;
	position: relative;
	margin-bottom: 33px;
}

.inner .btn.outline {
	min-width: 260px;
	padding: 10px;
}

.page-template-promotion #banner {
	padding: 44px 0 118px;
}

.title.small {
	font-weight: bold;
	font-size: 24px;
	line-height: 130%;
}

.brend-img {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 110px;
	margin-bottom: 10px;
}

/*
*  CAR Tabs
*/

#steps-v2 {
	margin-bottom: 40px;

	.tab-inner-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
	}

	.tab-nav-wrapper {
		border-bottom: 3px solid #000000;
		margin-bottom: 40px;
		justify-content: center;
		position: relative;
	}

	.tab-nav-inner {
    width: 25%;
	}

	.tab-nav {
    font-weight: bold;
    font-size: 144px;
    line-height: 169px;
		text-align: right;
		color: #DEDEDE; 
		position: relative;
		cursor: pointer !important; 

		&.active {
			color: #FCE300;
		}
	}

	.tab-nav-inner.active+.tab-nav-inner .tab-nav:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background: url(../images/long-arrow-right.svg);
    background-repeat: no-repeat;
    background-position: top right;
    top: 50%;
    transform: translate(0, -50%);
    left: -132px;
	}

}


#car {
	position: absolute;
	transition: 1.4s cubic-bezier(.1,.25,.55,1);
	width: 98px;
	height: 98px;
	background: url(../images/car.svg);
	background-repeat: no-repeat;
	background-size: cover;
	left: 0;
	bottom: -15px;
}

.car-2 #car{
	left: 25%;
}

.car-3 #car{
	left: 50%;
}

.car-4 #car{
	left: 75%;
}

/*
*  End CAR Tabs
*/



.vertical-text .line{
	transform: rotate(-90deg);
	text-orientation: mixed;
	transform-origin: 0 0;
	white-space: nowrap;
	position: absolute;	
	top: 100%;

	&.l-1 { left: 6px; top: 106%; } 

	&.l-2 { left: 119px; top: 91%; }

	&.l-3 { left: 183px; top: 96%;	}

	&.l-4 { left: 244px; top: 107%;	}

	.span {
		white-space: nowrap;
		word-break: unset;
	}
}

.s-141 {font-size: 141px;}
.s-36 {font-size: 36px;}
.s-40 {font-size: 40px;}
.s-48 {font-size: 48px;}
.s-64 {font-size:64px;}


.vertical-text.__design .line.l-2 {
	top: 95%;
	left: 108px;
}

.vertical-text.__design .line.l-3 {
	top: 103%;
}

.vertical-text.__design .line.l-4 {
	top: 98%;
}

.line.l-2, 
.line.l-1,
.line.l-3,
.line.l-4 {
	span {
		transition: .3s ease-in-out;
	}
}


.line.l-3:hover,
.line.l-1:hover {
	span {
		color: #000 !important;
	}
}

.line.l-2:hover, 
.line.l-4:hover {
	span {
		color: @accent_color !important;
	}
}


.about-digit {
	padding-top: 60px;
	
	.value {
		font-weight: 900;
		font-size: 144px;
		line-height: 1;
		display: inline;
		color: @accent_color;
	}

	.description {
    max-width: 135px;
    line-height: 20px;
	}

}

.faq .accordion button {
	background: #F5F5F5;
	box-shadow: none;
}

.error-page {
	padding: 120px 0;

	h1 {
		font-size: 141px;
		line-height: 1;
		margin: 0;
		color: @accent_color;
	}
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	footer#colophon {
    margin-top: auto;
	}
}


.blog-grid>div {
	display: block;
	margin-bottom: 30px;
	.single-blog {
		height: 100%;
	}	
}

.page-inner {
	padding: 25px 0;
	overflow: hidden;
}

div#primary {
	padding: 25px 15px;
}

h1.entry-title {
	font-family: Roboto;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 0;
}

img.attachment-post-thumbnail {
	width: 100%;
	height: auto;
}

.entry-content img {
	margin: 50px 0;
}

img {
	height: auto;
}

.contact-box {
	max-width: 488px;
	padding: 38px 50px 40px 50px;

	a {
		text-decoration: none;
		white-space: nowrap;
		color: #000;
	}

	.box-item {
		margin-bottom: 42px;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
	}
}


.socials-links {
	display: flex;
	justify-content: space-between;
	a {
		transition: .3s ease;
		&:hover {
			opacity: 0.6;
		}
	}
	img {
		display: block;
	}
}


.yellow-box:before {
	content: '';
	position: absolute;
	width: 40vw;
	height: 100%;
	background: #fce300;
	z-index: -1;
	top: 0;
	left: 0;
}

.yellow-box {
	position: relative;
	z-index: 1;
	margin-bottom: 38px;
	padding: 20px 0 20px 30px;
}

.yellow-box a {
	color: #000;
	text-decoration: none;
}

.yellow-box a,
.yellow-box-item {
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
}

.yelow-box-title {
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 20px;
}

.yellow-box-item a {
	margin-left: 10px;
}

.socials-links.__portfolio a img {
	text-align: center;
	display: inline-block;
}

.socials-links.__portfolio a {
	width: 50%;
	margin-bottom: 27px;
	text-align: center;
}

.socials-links.__portfolio {
	flex-wrap: wrap;
	max-width: 203px;
}

.page-inner h1 {
	margin-top: 0;
  margin-bottom: 46px;
}

@media (min-width: 768px) {
	.page-inner.__portfolio .col-md-9 {
		flex: 0 0 72.8%;
		max-width: 72.8%;
	}

	.page-inner.__portfolio .col-md-3 {
		flex: 0 0 27.2%;
		max-width: 27.2%;
	}
}

/*
* Custom attachment input
*/

span.box.attach-trigger {
	width: 35px;
	height: 35px;
	padding: 0;
	display: block;
	margin: 0;
	border-radius: 50%;
	background-image: url(../images/paperclip.svg);
	background-size: 17px;
	background-repeat: no-repeat;
	background-position: center;
}

span.attach-name {
	padding-left: 17px;
}

div#my-attach {
	cursor: pointer !important;
	margin: 0;
	position: absolute;
	bottom: 20px;
	left: 20px;
	background: #fff;
	padding-right: 10px;
	border-radius: 0 50px 50px 0;
}

.grouped-fields {
	position: relative;
	textarea {
		padding-bottom: 60px;
	}
}

/*
* End Custom attachment input
*/


/*
* Tab slider
*/
#tab-slider {
	margin-top: -41px;

	.tab-nav-wrapper {
    display: flex;
		position: relative;
		&>div {
			padding: 0;
		}
	}

	.tab-nav {
		min-height: 660px;
		position: relative;
		padding-top: 75px;
		cursor: pointer !important;	
		user-select: none;

		span {
			color: #000;
			transform: rotate(-90deg);
			text-orientation: mixed;
			transform-origin: 0 0;
			white-space: nowrap;
			position: absolute;
			top: 100%;
			top: 78%;
			font-weight: 700;
			font-size: 36px;
			line-height: 42px;
			left: 53px;
			transition: .3s ease-in-out;
		}

		img {
			position: absolute;
			transition: .3s ease;
			transform: translate(81px, -50px);
			opacity: 0;
		}

		&.active {
			background: #FCE300;

			span {
				color: #fff;
			}

			img {
				transition: .7s ease;
				transform: translate(81px, 119px);
				opacity: 1;
			}

		}

	}



	.tab-nav-wrapper {   
    border-bottom: 2px solid @accent_color;
	}

	.tab-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
	}

}

/*
* End Tab slider
*/

/*
* Digit
*/

.digit-circle {
	width: 290px;
	height: 290px;
	box-sizing: border-box;
	border: 10px solid @accent_color;
	border-radius: 50%;
	position: relative;
}

.col-md-8.__digitmod {
	padding-left: 10%;
}

.about-digit .digit-item {
	cursor: pointer !important;
	user-select: none !important;
}

.digit-inner {
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%) scale(1.6);
	width: 100%;
	font-size: 144px;	
	font-weight: 900;
	color: #fff;
	transition: .2s ease;
	opacity: 0;	
}

.active .digit-inner {
	transform: translate(0, -50%) scale(1);
	transition: .2s ease;
	opacity: 1;
	transition-delay: .2s;
}

.digit-circle:before {
	content: '';
	border-radius: 50%;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 50%;
	transition: .23s ease;
	transform: translate(0, -50%);
	background: transparent;
}

.digit-circle.active:before {
	width: 100%;
	height: 100%;
	background: @accent_color;
	transition: .23s ease;
}


/*
* End Digit
*/

#what-includes {
	overflow: hidden;
	position: relative;
}

.include-item {
	text-align: center;
	background: @accent_color;
	min-height: 340px;
}

.include-item-desc {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.include-item-title {
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 21px;
	min-height: 56px;
}

.include-item {
	text-align: center;
	background: #fce300;
	padding: 57px 20px;
}

#includes {
	padding-left: 280px;

	.owl-nav {
		position: absolute;
    width: 259px;
    left: 0;
    top: 57%;
		transform: translate(0, -50%);
	}

}

#what-includes .container {
	min-height: 340px;	
}

#includes {
	width: 1750px;

	.nth-0 {
		opacity: 1 !important;
	}
	.nth-1 {
		opacity: .8 !important;
	}
	.nth-2 {
		opacity: .6 !important;
	}
	.nth-3 {
		opacity: .4 !important;
	}	
	.nth-4 {
		opacity: .2 !important;
	}
	.cloned {
		opacity: .2;
	}
}

.slide-text-title {
	width: 260px;
	position: absolute;
	top: 143px;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
}

button.close-menu {
	position: absolute;
	right: 0;
	top: 0;
	box-shadow: none;
	background: transparent;
	border: none;
	font-size: 30px;
	padding: 19px;
}

.menu-open {
	height: 100vh;
	overflow: hidden;
}

.row.three-column-box>div {
	margin-bottom: 30px;
}

.section.accent .mb-90 {
	margin-bottom: 80px;
}

.single-blog img {
	width: 100%;
	height: auto;
}

#yamap {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.contacts .container {
	position: relative;
	z-index: 2;
}


/*
* Pagination
*/

.wp-easy-query-paging.clearfix {
	position: relative;
	padding: 0 100px;
}

a.prev.page-numbers {
	position: absolute;
	left: 0;
	display: inline-block;
	background: url(../images/arrow-left.svg)!important;
	background-repeat: no-repeat!important;
	-webkit-background-size: 25px 25px!important;
	-moz-background-size: 25px!important;
	background-size: 25px!important;
	background-position: top left!important;
	width: 100px;
	height: 15px;
	font-size: 0;
	&:before {
		content: '';
    position: absolute;
    width: calc(100% - 23px);
    left: 23px;
    bottom: 0;
    height: 2px;
    background: #000;
	}
}

a.next.page-numbers {
	position: absolute;
	right: 0;
	display: inline-block;
	background: url(../images/arrow-right.svg)!important;
	background-repeat: no-repeat!important;
	-webkit-background-size: 25px 25px!important;
	-moz-background-size: 25px!important;
	background-size: 25px!important;
	background-position: top right!important;
	width: 100px;
	height: 15px;
	font-size: 0;
	&:before {
		content: '';
    position: absolute;
    width: calc(100% - 23px);
    right: 23px;
    bottom: 0;
    height: 2px;
    background: #000;
	}
}

body .wp-easy-query-paging {
	margin: 0;
	border-top: none;
}

span.page-numbers.current,
a.page-numbers {
	padding: 0 5px;
}

.wp-easy-query-paging.clearfix a {
	color: #000;
}

span.page-numbers.current {
	color: @accent_color;
}

/*
* End Pagination
*/

#scroll-top {
  display: inline-block;
  background-color: @accent_color;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
	z-index: 1000;
	box-shadow: 0 4px 10px rgba(0,0,0,.1);
	
	&:before {
    content: '';
    width: 15px;
    height: 15px;
    border: 3px solid #000;
    position: absolute;
    top: 21px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
    border-right: none;
    border-bottom: none;
	}

}

#scroll-top.active {
  opacity: 1;
  visibility: visible;
}

.ymaps-2-1-76-ground-pane {
	filter: grayscale(1);
}
.ymaps-2-1-76-user-selection-none,
.ymaps-2-1-76-user-selection-none * {
	filter: grayscale(0) !important;
}

.wpcf7-list-item label{
	display: flex;
	align-items: center;

	input {
		margin-right: 10px;
	}
}

.wpcf7-radio label {
	flex-direction: row-reverse;
	text-align: left;
	justify-content: flex-end;
}

article#post-707 {
	padding-bottom: 50px;
}

.brief div.wpcf7-mail-sent-ok,
.brief div.wpcf7 .ajax-loader,
.brief div.wpcf7-acceptance-missing,
.brief div.wpcf7-validation-errors {
	display: block!important; 
}

div.wpcf7-mail-sent-ok {
	margin: 0;
	padding: 0;
	border: none;
	padding-top: 19px;
	padding-left: 20px;
	color: #398f14;
}

.include-item-icon {
    max-width: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 50px;
    position: absolute;
}

#blog-carousel img {
	width: auto!important;
	margin: 0 auto!important;
	padding-top: 1px!important;
}