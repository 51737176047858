.btn {

		background-color:@accent_color;		
		border:none;
		display:inline-block;
		cursor:pointer;
		text-decoration:none;
		
		color:@btn_color;
		border-radius: @radius;		
		padding: @btn_padding;
		font-size: @btn_font_size;
		text-transform: @btn_transform;
		font-weight: @btn_font_weight;
		letter-spacing: @btn_letter_spacing;
		.transition;

		&.outline {
			background: transparent;
			border: 2px solid #000000;
			&:hover {
				color: #fff;
			}
		}

		
		&:hover {
			background-color:@secondary_color;
			color: @btn_hove_color;
		}

		&.rounded {
			border-radius:28px;
		}

		&.squer {
			border-radius:0;
		}

}

.btn.grey {
	background: #DEDEDE;
	padding: 7.5px 15px;
}

body {

	.hamburger-inner,
	.hamburger-inner:after,
	.hamburger-inner:before {
		width: 30px;
		height: 3px;
		border-radius: 0px;
	}

	.hamburger-box {
		width: 30px;
		height: 20px;
	}

	@media (max-width: 991px) {
	.hamburger {
			padding: 5px;
			display: flex;
			align-items: center;
			margin-left: 30px;
		}
	}
}