ul.menu {
	display: flex;
	list-style: none;
	margin: 0;
	padding-left: 22px;

	a {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
			color: #000;
			text-decoration: none;
			margin: 0 18.7px;
			text-align: center;
			padding-bottom: 3px;
			border-bottom: 2px solid transparent;
			transition: .3s ease;

			&:hover {
				border-bottom: 2px solid @accent_color;
			}
	}

}

ul.menu li.current_page_item a {
	border-bottom: 2px solid @accent_color;
}

