input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="time"],
input[type="url"],
input[type="tel"],
textarea,
select 
{
	.transition;
	outline: none;
	box-sizing: border-box;
	width: 100%;
	background: @input_background;
	margin-bottom: @input_margin_bottom;
	border: @input_border;
	border-radius: @input_radius;	
	padding: @input_padding;
	color: @input_color;
	font-family: @input_font;	
	font-weight: @input_font_weight;
	font-size: @input_font_size;
	text-transform: @input_text_transform;
}

.brief {
input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="time"],
input[type="url"],
input[type="tel"],
textarea,
select 	{
	background-color: #f5f5f5;
	margin-bottom: 11px;
	}
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
	margin-left: 15px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: @input_color;
	font-weight: @input_font_weight;
}
::-moz-placeholder { /* Firefox 19+ */
	color: @input_color;
	font-weight: @input_font_weight;
}
:-ms-input-placeholder { /* IE 10+ */
	color: @input_color;
	font-weight: @input_font_weight;
}
:-moz-placeholder { /* Firefox 18- */
	color: @input_color;
	font-weight: @input_font_weight;
}

textarea {
	border-radius: 0;
	resize: none;
	height: 197px;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus,
select:focus
{
	outline: none;
}

input[type="submit"],
input[type="button"]{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	padding: 10px;
	background: @accent_color;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;	
	color: #000;
	text-transform: uppercase;
}

input[type="submit"]:hover,
input[type="button"]:hover{
	background: #000;
	color: #fff;
}

button {
	text-transform: uppercase;
}

span.wpcf7-list-item { display: table-row; }
span.wpcf7-list-item * { display: table-cell; }

textarea.wpcf7-not-valid,
input.wpcf7-not-valid {
	background: #ffd2cf!important;
}

form.loading .modal-inner,
form.loading .form-inputs-wrapper > div {
	opacity: 0.7;
}

.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok {
	display: none!important;
}

.wpcf7-submit.btn {
	width: 275px;
	white-space: nowrap;
}

.wpcf7-submit.btn.active {
	z-index: 999;
	position: relative;
}

.ufo {
	top: -460px;
	left: 24px;
	z-index: 999;
	transform: translate(-1200px, 0);
	position: absolute;

	.ship {	
		width: 232px;
		height: 163px;
		position: absolute;
		background: url(../images/ufo.png);
		background-size: cover;
		background-repeat: no-repeat;
		opacity: 0;	
		transition: .1s ease;
	}

	.liser {
    width: 0;
    height: 0;
		top: 72px;
    left: -2px;   
    border-bottom: 0px solid rgba(252, 227, 0, 0.69);
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    display: block;
		z-index: -1;
		transition: .5s ease;
	}
}

.ufo-is-here {

	.ufo .ship {
		opacity: 1;
	}

	.fancybox-content {
		overflow: visible!important;
	}

	.lp-overflow {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 99;
		background: rgba(0, 0, 0, 0.6);
	}
	
	.owl-carousel {
		z-index: 0;
	}

	.ufo {	
    left: 17px;	
		transform: translate(0, 0);
		transition: 1s ease;
	}

	.liser {
		border-bottom: 390px solid rgba(252, 227, 0, 0.69);
		border-left: 123px solid transparent;
    border-right: 123px solid transparent;
		transition: border-bottom .5s ease;
		transition-delay: 1s;
	}

}

.ufo-gone {

	.ufo {
		transform: translate(1200px, 0);
	}

	.liser {
		border-bottom: 0 solid rgba(252, 227, 0, 0.69);
		border-left: 0 solid transparent;
    	border-right: 0 solid transparent;
		transition: border-bottom .5s ease;	
	}
}

.brief p {
	margin: 0;
}


select.wpcf7-not-valid {
	background: #ffd2cf!important;
}