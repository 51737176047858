body .modal {

	display: none;
	min-width: 300px !important;
	max-width: 500px !important;
	border-radius: 5px;
	padding: 30px 54px !important;

	&#modal-1 {
		width: 100%;
		max-width: 700px !important;
	}

	button.fancybox-close-small {
    right: 44px;
    top: 11px;
	}

	textarea,
	input {
		background: #F5F5F5;
		margin-bottom: 11px;
	}

	textarea {
		height: 95px;
    border-radius: 0 22.5px 22.5px 0;
	}

	input.btn {
    background: #fce300;
		width: 275px;
    padding: 12px 10px;
    text-transform: none;
		&:hover {
			background: #000;
		}
	}

	.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
		text-align: center;
		margin-bottom: 25px;
	}

	.subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
		color: #7D7D7D;
		margin-bottom: 10px;
	}

	label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7D7D7D;
	}

	.modal-body p {
    margin: 0;
	}


}

.modal-inner-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #7D7D7D;
	margin-bottom: 15px;
}