
//LOGO
@logo_max_width				: 170px;

//MAIN
@html_color						: #f2f2f2;
@body_color						: #ffffff;
@body_max_width				: 1920px;

@font_family					: 'Roboto';
@font_size						:	18px;
@font_weight					:	500;

@title_font						: @font_family;
@title_font_size 			: 46px;
@title_wrapper_mb			: 40px;

//SECTION PADDING
@section_padding			:89px;

//BUTTONS
@radius								: 50px;
@btn_transform				: none;
@btn_font_size				: 18px;
@btn_padding					: 8px 35px;
@btn_color						: #000;
@btn_hove_color				: #fff;
@btn_font_weight			: 600;
@btn_letter_spacing		: 0;

//TRANSITIONS
.transition {
	transition 					: .23s ease-in;
};

//COLORS MAIN
@font-color						: #000;
@font-color2					: #000;

@accent_color					: #FCE300;
@secondary_color			: #000;
@bg_color							: #f2f4f6;

//COLORS ADITIONAL
@color_1							: #F5F5F5;
@color_2							: #F5F5F51;
@color_3							: #F5F5F5;

//FOOTER
@footer_bg						: #000;
@footer_color					: #fff;

//MOBILE MENU
@mobile_menu_bg				: #000;
@mobile_menu_color		: #fff;

//INPUTS
@input_font						: @font_family;
@input_font_size			: 18px;
@input_radius					: 0 50px 50px 0;
@input_background			: #fff;
@input_margin_bottom	: 27px;
@input_border					: 1px solid transparent;
@input_padding				: 13px 19px;
@input_color					: #7D7D7D;
@input_font_weight		:	500;
@input_text_transform	: none;

